import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { graphql, Link } from "gatsby";
import { MenuListing } from "../components/MenuListing";
import { IAppProps } from ".";

export const query = graphql`
query AlaCarteMenuQuery {
  alacarteAppetisersJson {
    items {
      name
      description
    }
  }
  alacarteSecondJson {
    items {
      description
      id
      name
    }
  }
  alacarteSoupJson {
    items {
      description
      id
      name
    }
  }
  alacarteChickenJson {
    items {
      description
      id
      name
    }
  }
  alacarteDuckJson {
    items {
      description
      id
      name
    }
  }
  alacartePorkJson {
    items {
      description
      id
      name
    }
  }
  alacarteBeefJson {
    items {
      description
      id
      name
    }
  }
  alacarteLambJson {
    items {
      description
      id
      name
    }
  }
  alacarteSeafoodJson {
    items {
      description
      id
      name
    }
  }
  alacarteSizzlingJson {
    items {
      description
      id
      name
    }
  }
  alacarteChineseVegetablesJson {
    items {
      description
      id
      name
    }
  }
  alacarteVegetablesJson {
    items {
      description
      id
      name
    }
  }
  alacarteRiceNoodlesJson {
    items {
      description
      id
      name
    }
  }
  metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
      original{src
      }
    }
  }
}
`;

export default class AlaCarteMenuPage extends React.Component<IAppProps, {}>
{
    constructor(props: IAppProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="alacarte">
                <SEO title="À La Carte Menu" image={this.props.data.metaImage.childImageSharp.original.src} />

                <section className="hero hero-sub-page">
                    <div className="hero-content text-center">
                        <div className="content">
                            <h1>À La Carte Menu </h1>
                            <p className="lead">
                                Find our extensive À La Carte menu below.
                            </p>
                            <div className="button-group" style={{ width: "600px", maxWidth: "100%", margin: "0 auto" }}>
                                <Link to="/book" title="Book a table" className="btn btn-outline btn-white">
                                    Book a table
							    </Link>
                                <Link to="/menus" title="View our menus" className="btn btn-outline btn-white">
                                    View all menus
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Appetisers</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteAppetisersJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Second Course</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteSecondJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Soup</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteSoupJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Duck</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteDuckJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Lamb</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteLambJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Chicken</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteChickenJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Seafood</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteSeafoodJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Beef</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteBeefJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Pork</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacartePorkJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Sizzling</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteSizzlingJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Chinese Vegetables</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteChineseVegetablesJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Mixed Vegetables</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteVegetablesJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>À La Carte <span>Rice and Noodles</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.alacarteRiceNoodlesJson.items} />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}